import './App.css';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Navbar/>
      <Header {...header}/>
      <div className={"bg-purple-200"}>
        <div className={"bg-gradient-to-bl from-[#ffffff] to-blue-400"}>
          <div className="lg:grid lg:grid-cols-2 content-start w-full h-full " style={{backgroundImage: `linear-gradient(0deg, #5ca9fbff, #5ca9fbff, #5ca9fbdd, #5ca9fb44, #5ca9fb00)`}}>
            <div className={"text-center justify-center pt-[4rem] lg:pt-[0.3rem] px-12 pb-12 lg:m-12 careers:pt-[4rem] "}>
              <h1 className="uppercase text-4xl text-blue-500 font-semibold lg:text-white lg:text-6xl">Links:</h1>
              <div className='mt-16 text-gray-100 rounded-lg bg-blue-900/75 p-4'>
                <div className='rounded-lg duration-200 hover:bg-sky-600 py-4 m-4'>
                  <a href='/machine-readable-file.json' target={'_blank'} className='text-left list-disc  text-white uppercase' rel="noreferrer">
                    <h1 className='text-blue-400 text-2xl'>Machine Readable File</h1>
                  </a>
                </div>
              </div>
            </div>
            <div className={"top-0 text-center pt-[4rem] lg:m-12"}>
              <h1 className="text-2xl font-semibold text-white uppercase lg:text-6xl">Our Benefits</h1>
              <div className="mx-12 lg:m-16 rounded-lg bg-gradient-to-tl from-[#ffffff00] to-blue-900 px-4">
                <ul className="text-left list-disc p-4 text-white uppercase">
                  <li>Retirement Savings - Matching 401k, Immediately Vested</li>
                  <li>Medical, Dental, and Vision Coverage</li>
                  <li>Paid Time Off (PTO)</li>
                  <li>Holiday Leave</li>
                  <li>Tuition and Training Reimbursement</li>
                  <li>Merit Based Bonuses</li>
                </ul>
              </div>
            </div>
          </div> 
        </div>
      </div>
      <Footer/>
    </>
  );
}

const header= {
  sectionImage: "https://media.istockphoto.com/photos/healthcare-benefits-picture-id483264595?b=1&k=20&m=483264595&s=612x612&w=0&h=xfaC9nJytWXUgSOp_5gWcvVMGX2JbxUErc8zBMtTIYg=",
  title: "TTS Health & ",
  titleBold: "Benefits",
  smallHeader: true
}
export default App;
