import React from 'react';

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
    }, []);
    return (
        <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
        >
        {props.children}
        </div>
    );
}

class Header extends React.Component {
    render() {
        return (
            <>
                <header className=''>
                    <div className={"bg-center bg-cover " + (this.props.smallHeader ? "h-[26rem]" : "h-[54rem]")}style={{backgroundImage: `url(${this.props.sectionImage})`} }>
                        <div className=" items-center w-full h-full bg-gray-900 bg-opacity-70 ">
                            <div className={"text-center justify-center" + (this.props.smallHeader ? " pt-[12rem]" : " pt-[24rem]")}>
                                <FadeInSection>
                                    <h1 className="text-2xl font-semibold text-white uppercase lg:text-6xl">{this.props.title} <span className="text-blue-400">{this.props.titleBold}</span></h1>
                                </FadeInSection>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        )
    }
}
export default Header
