import React from 'react'

function Footer() {
    return (
        <div className='transition-transform bg-gradient-to-br from-[#425664] to-gray-900  z-50'>
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.11.2/css/all.css"
          />

          <footer className="text-gray-300 body-font">
            <div
              className="
                container
                px-5
                py-5
                mx-auto
                flex
                md:items-center
                lg:items-start
                md:flex-row md:flex-nowrap
                flex-wrap flex-col
              "
            >
              <div
                className="
                  flex-grow flex flex-wrap
                  md:pl-20
                  -mb-10
                  md:mt-0
                  mt-10
                  md:text-left
                  text-center
                "
              >
                <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                  <h2
                    className="
                      title-font
                      font-medium
                      text-gray-900
                      tracking-widest
                      text-sm
                      mb-3
                    "
                  >
                    About Us
                  </h2>
                  <nav className="list-none mb-10">
                    <li>
                      <a href="https://www.tts-site.com/core-competencies" className="text-gray-300 hover:text-gray-800"
                        >Core Competencies</a
                      >
                    </li>
                  </nav>
                </div>
                <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                  <h2
                    className="
                      title-font
                      font-medium
                      text-gray-900
                      tracking-widest
                      text-sm
                      mb-3
                    "
                  >
                    Working with TTS
                  </h2>
                  <nav className="list-none mb-10">
                    <li>
                      <a href="https://www.tts-site.com/contact-us" className="text-gray-300 hover:text-gray-800"
                        >Contact Us
                      </a>
                    </li>
                    <li>
                      <a href="https://www.tts-site.com/working-with-tts" className="text-gray-300 hover:text-gray-800"
                        >Working with us
                      </a>
                    </li>
                  </nav>
                </div>
                <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                  <h2
                    className="
                      title-font
                      font-medium
                      text-gray-900
                      tracking-widest
                      text-sm
                      mb-3
                    "
                  >
                    Careers
                  </h2>
                  <nav className="list-none mb-10">
                    <li>
                      <a href="https://www.tts-site.com/careers" className="text-gray-300 hover:text-gray-800"
                        >Open Positions
                      </a>
                    </li>
                  </nav>
                </div>
              </div>
              <div
                className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left"
              >
                <a
                  href="/"
                  className="
                    flex
                    title-font
                    font-medium
                    items-center
                    md:justify-start
                    justify-center
                    text-gray-900
                  "
                >
                  <i className="fas fa-cubes fa-lg text-coal-500"></i>
                  <span className="ml-3 text-xl">TTS</span>
                </a>
                <p className="mt-2 text-sm text-gray-300">
                  Teaming Towards Success
                </p>
              </div>
            </div>
            <div className="">
              <div
                className="
                  container
                  mx-auto
                  py-4
                  px-5
                  flex flex-wrap flex-col
                  sm:flex-row
                "
              >
                <p className="text-gray-200 text-sm text-center sm:text-left">
                  © 2021 Copyright:
                  <a
                    href="https://www.tailwind-elements.com/"
                    rel="noreferrer"
                    className="text-gray-200 ml-1"
                    target="_blank"
                    >Training Technologies and Support</a
                  >
                </p>
                <span
                  className="
                    inline-flex
                    sm:ml-auto sm:mt-0
                    mt-2
                    justify-center
                    sm:justify-start
                  "
                >
                  <a href="/" className="text-gray-400">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="/" className="ml-3 text-gray-400">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="/" className="ml-3 text-gray-400">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  <a href="/" className="ml-3 text-gray-400">
                    <i className="fab fa-youtube"></i>
                  </a>
                  <a href="/" className="ml-3 text-gray-400">
                    <i className="fab fa-instagram"></i>
                  </a>
                </span>
              </div>
            </div>
          </footer>
        </div>
    )
}

export default Footer
