import React, { useState, useCallback, useEffect, useRef } from 'react'

export default function Navbar({fixed}) {

  const navRef = useRef(null);
  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        navRef.current.className = "relative z-50 w-full float-right sticky top-0 shadow-xl opacity-80 bg-white mx-auto px-4 sm:px-6 duration-150 py-6 hover:opacity-95"
        
      } else if (y < window.scrollY) {
        navRef.current.className = "relative z-50 w-full float-right bg-white sticky top-0 shadow-xl opacity-60 mx-auto px-4 sm:px-6 duration-300 py-3 hover:opacity-95"
      }
      if(window.scrollY === 0){
        
        navRef.current.className = "relative z-50 rounded-bl-md w-2/3 float-right bg-white sticky top-0 shadow-xl mx-auto px-4 sm:px-6 py-6 duration-150 opacity-100";
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <>
      <div ref={navRef} style={{fontFamily: "Raleway, sans-serif"}} className="font-semibold  z-50 text-white uppercase relative opacity-100 rounded-bl-md float-right w-2/3 bg-white sticky top-0 shadow-inner mx-auto px-4 sm:px-6 py-6 duration-150 hover:opacity-95">
        <div className="">
          <div className="flex justify-between items-center border-gray-100 lg:justify-start lg:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="/">
                <img className="h-8 w-auto sm:h-10 rounded-md" src="https://le-cdn.website-editor.net/e70027421710413197bd803f1fdfd34f/dms3rep/multi/opt/tts-logo-1920w.png" alt=""/>
              </a>
            </div>
            <div className="-mr-2 -my-2 lg:hidden">
              <button type="button" className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:text-gray-300" aria-expanded="false">
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>
            <nav className="hidden lg:flex space-x-10" id='elementId'>
              <p className="text-base font-medium text-gray-700 hover:text-gray-900 hover-underline-animation">
                Health and Benefits Subdirectory
              </p>
            </nav>
            <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">
              <a href="https://www.tts-site.com" className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent duration-150 rounded-md shadow-sm text-base font-medium text-white bg-blue-400 hover:bg-blue-800">
                Back to our Main Site
              </a>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}

 